<template>
  <div v-if="smallDisplay" class="tsb_home_status_card">
    <div class="tsb_home_icon" :class="serviceClass" >
      <svg class="tsb_home_svg">
        <use :xlink:href="iconLink" :href="iconLink"></use>
      </svg>
    </div>
    <div class="tsb_home_service">
      <a :href="baseUrl + timetable">{{ timetable }}</a>
    </div>
    <div class="tsb_home_status"> 
      <div :class="statusClass" class="tsb_home_status_indicator"></div>
      <p>{{ status }}</p>
    </div>
  </div>
  <div v-else class="tsb_internal_status_card">
    <div class="tsb_internal_status"> 
      <div :class="statusClass" class="tsb_internal_status_indicator" role="img" :aria-labelledby="statusClass"></div>
    </div>
    <a class="tsb_internal_link" :href="baseUrl + timetable">
      <div class="tsb_internal_service" :class="serviceClass">
        <p>{{ timetable }}</p>
        <svg class="tsb_internal_svg">
          <use :xlink:href="iconLink" :href="iconLink"></use>
        </svg>
      </div>
    </a>
    <div class="tsb_internal_route">
      <p>{{ serviceLocations }}</p>
    </div>
  </div>
</template>

<script>
const CONSTANTS = {
  BASE_URL_TRAIN: "/plan-your-journey/train-lines/",
  BASE_URL_COACH: "/plan-your-journey/coach-lines/",
  ICON_TRAIN: "/Portals/_default/skins/transwa%202020/static/src/svg-icons/train.svg#Layer_1",
  ICON_COACH: "/Portals/_default/skins/transwa%202020/static/src/svg-icons/bus.svg#Layer_1",
  LOCATIONS: {
    PROSPECTOR: "East Perth to Kalgoorlie",
    AUSTRALIND: "Perth to Bunbury",
    AVONLINK: "Midland to Northam",
    MERREDINLINK: "East Perth to Merredin",
    GE1: "East Perth to Esperance",
    GE2: "East Perth to Esperance",
    GE3: "Kalgoorlie to Esperance",
    GE4: "Albany to Hopetoun",
    GS1: "East Perth to Albany",
    GS2: "East Perth to Albany",
    GS3: "East Perth to Albany",
    N1: "East Perth to Kalbarri",
    N2: "East Perth to Geraldton/Kalbarri",
    N3: "East Perth to Geraldton",
    N4: "Geraldton to Meekatharra",
    N5: "East Perth to Geraldton",
    SW1: "East Perth to Augusta/Pemberton",
    SW2: "East Perth to Pemberton",
    SW3: "East Perth to Pemberton",
      SW4: "East Perth to Boyup Brook",
    SW5: "Bunbury to Collie"
  }
};

export default {
  name: "ServiceCard",
  props: {
    timetable: String,
    status: String,
    smallDisplay: Boolean
  },
  computed: {
    isTrainService: function() {
      return this.timetable.length > 3;
    },
    baseUrl: function() {
      return (this.isTrainService ? CONSTANTS.BASE_URL_TRAIN : CONSTANTS.BASE_URL_COACH);
    },
    iconLink: function() {
      return (this.isTrainService ? CONSTANTS.ICON_TRAIN : CONSTANTS.ICON_COACH);
    },
    serviceClass: function() {
      if (this.isTrainService) {
        return "tsb_" + this.timetable.toLowerCase();
      }
      else {
        switch (this.timetable.slice(1,2)) {
          case "E":
            return "tsb_coach_ge";
          case "S":
            return "tsb_coach_gs";
          case "W":
            return "tsb_coach_sw";
          default:
            return "tsb_coach_n";
        }
      }
    },
    statusClass: function() {
      switch (this.status) {
        case 'Cancelled':
          return "tsb_status_cancelled";
        case 'Delayed':
          return "tsb_status_delayed";
        case 'On Time':
        default:
          return "tsb_status_on_time";
      }
    },
    serviceLocations: function() {
      return CONSTANTS.LOCATIONS[this.timetable.toUpperCase()];
    }
  }
};
</script>

<style scoped>
/* START COLOURS */
.tsb_status_cancelled {
  background-color: #cc3232;
}
.tsb_status_delayed {
  background-color: #e7b416;
}
.tsb_status_on_time {
  background-color: #84bd00;
}
.tsb_australind {
  background-color: #614b79;
}
.tsb_prospector {
  background-color: #f2a900;
}
.tsb_avonlink,
.tsb_merredinlink {
  background-color: #8f993e;
}
.tsb_coach {
  background-color: #5f8fab;
}
.tsb_coach_ge {
  background-color: #dc8633;
}
.tsb_coach_gs {
  background-color: #3e6991;
}
.tsb_coach_n {
  background-color: #af272f;
}
.tsb_coach_sw {
  background-color: #5e7e29;
}
/* END COLOURS */

/* START HOMEPAGE STYLING */
.tsb_home_status_card {
  display: flex;
  max-width: 360px;
  padding: 15px;
  align-items: center;
}

.tsb_home_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin: 0px 15px;
}

.tsb_home_service,
.tsb_home_status {
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
}

.tsb_home_service {
  margin: 0 20px;
  min-width: 120px;
}

.tsb_home_service a {
  color: #444444;
  font-size: 20px;
  width: 100%;
}

.tsb_home_status {
  font-size: 12px;
  min-width: 100px;
  height: 25px;
  display: flex;
  justify-content: left;
  background-color: #ffffff;
  border-radius: 50px;
}

.tsb_home_status_indicator {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin: 0 10px 0 15px;
}

.tsb_home_status p {
  margin: 0;
  color: #444444;
  font-weight: bold;
}

.tsb_home_svg {
  fill: #ffffff;
  width: 25px; 
  height: 25px;
}

@media screen and (min-width: 768px) and (max-width: 999px) {
  .tsb_home_status_card {
    padding: 15px 0px;
  }

  .tsb_home_icon {
    margin: 0px 5px;
  }

  .tsb_home_service {
    margin: 0px 5px 0px 0px;
  }

  .tsb_home_status {
    min-width: 95px;
  }

  .tsb_home_status_indicator {
    margin: 0px 5px 0px 10px;
  }
}

/* END HOMEPAGE STYLING */

/* START INTERNAL STYLING */
.tsb_internal_status_card {
  width: 220px;
  height: 180px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.tsb_internal_link {
  display: flex;
  justify-content: center;
  margin: 0px 25px;
}

.tsb_internal_status {
  height: 15px;
  display: flex;
  direction: rtl;
}

.tsb_internal_status_indicator {
  width: 14px;
  height: 14px;
  border-radius: 10px;
  margin: 6px;
}

.tsb_internal_service {
  height: 100px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tsb_internal_service:hover {
  filter: brightness(0.8);
}

.tsb_internal_service p {
  margin: 0;
  color: #ffffff;
  font-size: 18px; 
  font-weight: bold;
}

.tsb_internal_svg {
  height: 35px;
  width: 35px;
  fill: #ffffff;
  margin-top: 12px;
}

.tsb_internal_route {
  font-weight: bold;
  font-size: 18px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  text-align: center;
}

.tsb_internal_route p {
  margin: 0;
  line-height: 24px;
}
/* END INTERNAL STYLING */

</style>