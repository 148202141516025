import axios from "../managers/axios";
import moment from "moment";
const serviceManager = {
    async getServices(date) {
        if (window.dnninjectable.serviceApi) {
            const { config, url } = window.dnninjectable.serviceApi;
            return (c => c.data)(await axios.get(url, config));
        }
        return (c => c.data)(
            await axios.get(
                `service/service-numbers?date=${moment(date).format(
                    moment.HTML5_FMT.DATE
                )}`
            )
        );
    }
};

export default serviceManager;
