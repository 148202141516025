<template>
  <div id="app" class="tsb_transwa_status_board">
    <TranswaStatusBoard />
  </div>
</template>

<script>
import TranswaStatusBoard from './components/TranswaStatusBoard.vue'

export default {
  name: 'App',
  components: {
    TranswaStatusBoard
  }
}
</script>

<style scoped>
.tsb_transwa_status_board {
  display: flex;
  justify-content: center;
}
</style>