<template>
  <div v-if="!preloading" :class="moduleWidth">
    <template v-if="moduleType === 'all'">
      <label class="tsb_select_label" for="tsb_service_lines">Select a line to view status:</label>
      <select name="service_lines" id="tsb_service_lines" v-model="lineSelected">
        <option v-for="serviceLine in serviceLinesList" :value="serviceLine" :key="serviceLine.id">
          {{ serviceLine }}
        </option>
      </select>
    </template>
    <template v-if="moduleType === 'trains' || moduleType === 'all'">
        <div class="tsb_homepage_title">
            <h3>Service Status</h3>
        </div>
        <div :class="moduleClass">
            <div v-for="timetable in trainTimetablesList" :key="timetable.id" class="tsb_service_homepage">
                <ServiceCard :timetable="timetable" :status="getTimetableStatus(timetable)" :smallDisplay="true"></ServiceCard>
            </div>
        </div>
        <div class="tsb_internal_information">
            <p>{{ loadTimeMessage }}</p>
            <a href="/updates/departures-and-arrivals" class="tsb_read_more">Read more</a>
        </div>
    </template>
    <template v-if="moduleType === 'coaches' || moduleType === 'all'">
        <div class="tsb_homepage_title">
            <h3>Service Status</h3>
        </div>
        <div :class="moduleClass">
            <div v-for="timetable in coachTimetablesList" :key="timetable.id" class="tsb_service_homepage">
                <ServiceCard :timetable="timetable" :status="getTimetableStatus(timetable)" :smallDisplay="true"></ServiceCard>
            </div>
        </div>
        <div class="tsb_internal_information">
            <p>{{ loadTimeMessage }}</p>
            <a href="/updates/departures-and-arrivals" class="tsb_read_more">Read more</a>
        </div>
    </template>
    <template v-if="moduleType === 'homepage'">
      <div class="tsb_homepage_title">
        <h3>Service Status</h3>
      </div>
      <div :class="moduleClass">
        <div v-for="timetable in trainTimetablesList" :key="timetable.id" class="tsb_service_homepage">
          <ServiceCard :timetable="timetable" :status="getTimetableStatus(timetable)" :smallDisplay="true"></ServiceCard>
        </div>
      </div>
      <div class="tsb_homepage_information">
        <p>{{ loadTimeMessage }}</p>
        <a href="/updates/departures-and-arrivals" class="tsb_read_more">Read more</a>
      </div>
    </template>
  </div>
</template>

<script>
import "moment-duration-format";
import serviceManager from "../managers/service-manager";
import moduleTypeManager from "../managers/module-type-manager";
import ServiceCard from "./ServiceCard";

const COACH_TIMETABLES = {
  GE1:["EP7", "EP1", "EP2", "EP8"],
  GE2:["EP3", "EP5", "EP4", "EP6"],
  GE3:["671", "651", "700", "690"],
  GE4:["543", "544"],
  GS1:["AP5", "AP1", "AP7", "411", "AP6", "AP2", "AP8"],
  GS2:["511", "473", "515", "475", "531", "488", "460", "528", "480", "522"],
  GS3:["335", "333", "336", "334"],
  N1:["879", "GPE1", "GPE2", "860"],
  N2:["891", "851", "920"],
  N3:["811", "800"],
  N4:["931", "940"],
  N5:["PG1", "PG2", "PG3", "PG4"],
  SW1:["271S", "271", "253", "277", "279", "276", "254", "278", "280S", "280"],
  SW2:["315", "319", "316"],
  SW3:["321", "322"],
  SW4:["115", "116"],
  SW5:["CB53", "CB02"]
};
const TRAIN_TIMETABLES = {
  Australind:["B03", "B55", "B02", "B56"],
  Prospector:["PL01", "PA51", "PA01", "PA53", "PA55", "PL02", "PA52", "PA02", "PA54", "PA56"],
  AvonLink:["AV01", "AV02"],
  MerredinLink:["M01", "M02", "M04", "M06"]
};
const ALL_TIMETABLES = {...TRAIN_TIMETABLES, ...COACH_TIMETABLES};

export default {
  name: "TranswaStatusBoard",
  data: () => ({
    services: [],
    preloading: true,
    loadTime: "",
    moduleType: "homepage",
    lineSelected: "Select line",
    windowWidth: 0
  }),
  computed: {
    coachTimetablesList: function() {
      return Object.keys(COACH_TIMETABLES);
    },
    trainTimetablesList: function () {
      return Object.keys(TRAIN_TIMETABLES);
    },
    serviceLinesList: function () {
      return ["Select line"].concat(this.trainTimetablesList, this.coachTimetablesList);
    },
    moduleClass: function() {
      return (this.moduleType === "homepage" ? "tsb_module_homepage" : "tsb_module_internal");
    },
    loadTimeMessage: function() {
      var hour = this.loadTime.getHours();
      var minute = this.loadTime.getMinutes();
      var ampm = hour >= 12 ? "pm" : "am";
      hour = hour % 12;
      hour = hour ? hour : 12;
      minute = minute < 10 ? "0" + minute : minute;

      var time = hour + ":" + minute + ampm;
      var date = this.loadTime.getDate() + "/" + (this.loadTime.getMonth() + 1) + "/" + this.loadTime.getFullYear();

      return "Last updated: " + date + " " + time;
    },
    moduleWidth: function () {
      return (this.moduleType === "homepage" ? "tsb_module_container" : "");
    }
  },
  methods: {
    getServicesByTimetable: function (timetable) {
      var timetableServiceNumbers = ALL_TIMETABLES[timetable];
      return this.services.filter(service => timetableServiceNumbers.indexOf(service.serviceNumber) !== -1);
    },
    getTimetableStatus: function(timetable) {
      var filtered = this.getServicesByTimetable(timetable);
      var cancelled = false;
      var delayed = false;

      for (var i = 0; i < filtered.length; i++) {
        if (filtered[i].cancelled) {
          cancelled = true;
        }
        if (filtered[i].delay.currentDelay !== 0) {
          delayed = true;
        }
      }

      if (cancelled) {
        return "Cancelled";
      }
      if (delayed) {
        return "Delayed";
      }
      return "On Time";
    },
    getDimmedStatus: function(timetable) {
      if (this.lineSelected !== timetable && this.lineSelected !== "Select line")
      {
        return "tsb_dimmed";
      }
      else {
        return "tsb_active";
      }
    },
    handleResize: function() {
      this.windowWidth = window.innerWidth;
    },
    loadSettings: async function() {
      const p = await Promise.all([
        serviceManager.getServices(new Date()),
        (this.moduleType = moduleTypeManager.getModuleType())
      ]);

      this.services = p[0];
      this.preloading = false;
      this.loadTime = new Date();
    }
  },
  created: function() {
    window.addEventListener("otterSettingsLoaded", this.loadSettings);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy: function() {
    window.removeEventListener("otterSettingsLoaded", this.loadSettings); 
    window.removeEventListener('resize', this.handleResize);
  },
  components: {
    ServiceCard
  },
};
</script>

<style scoped>
.tsb_module_container {
    max-width: 360px;
    width: 100%;
}

.tsb_module_internal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1080px;
}

.tsb_service_internal {
  padding: 15px 15px;
}

@media screen and (max-width: 768px) {
  .tsb_service_internal {
    padding: 0px;
  }
}

.tsb_internal_information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  margin-top: 20px;
}

.tsb_internal_information .tsb_read_more{
  font-size: 20px;
}

.tsb_status_legend {
  display: flex;
  flex-direction: row;
  width: 350px;
  align-items: center;
  margin-right: 50px;
  padding: 0px 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  height: 20px;
  justify-content: space-between;
}

.tsb_status_legend_on_time {
  height: 10px; 
  width: 10px;
  border-radius: 10px;
  background-color: #84bd00;
}

.tsb_status_legend_delayed {
  height: 10px; 
  width: 10px;
  border-radius: 10px;
  background-color: #e7b416;
}

.tsb_status_legend_cancelled {
  height: 10px; 
  width: 10px;
  border-radius: 10px;
  background-color: #cc3232;
}

.tsb_status_legend_text {
  font-size: 12px;
  margin-left: -50px;
}

.tsb_module_homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 360px;
}

.tsb_module_homepage>div:not(:last-child) {
  border-bottom: solid 1px #cccccc;
}

.tsb_module_homepage>div:last-of-type{
  border-bottom: solid 2px #000000;
}

.tsb_homepage_information {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
}

.tsb_homepage_information p {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.tsb_homepage_title {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: #cf4520;
  padding: 0px 50px;
  height: 100px;
}

.tsb_homepage_title h3 {
  margin: 0px;
  color: #ffffff;
}

.tsb_title {
  margin-bottom: 0;
}

.tsb_load_time {
  font-size: 12px;
}

.tsb_read_more {
  color: #cf4520;
  font-size: 16px;
}

.tsb_dimmed {
  opacity: 0.25;
  transition: 0.2s;
}

.tsb_active {
  transition: 0.2s;
}

.tsb_select_label {
  display: inline-block;
  margin: 0px 20px 20px 0px;
}

#tsb_service_lines {
  border: 1px solid #303133;
  border-radius: 5px;
  padding: 0px 8px;
}

.tsb_transwa_status_board .tsb_module_internal > .tsb_service_homepage {
    width: 50%;
    display: flex;
    border-right: 1px solid #ccc;
}

@media screen and (min-width: 769px) {
    .tsb_module_internal .tsb_service_homepage:nth-child(2n+2) {
        border: none;
    }
}

@media screen and (max-width: 768px) {
    .tsb_transwa_status_board .tsb_module_internal > .tsb_service_homepage {
        width: 100%;
        border: none;
    }
}

</style>