const moduleTypeManager = {
    getModuleType() {
        if (window.dnninjectable.moduleType) {
            return window.dnninjectable.moduleType;
        }
        else {
            return "homepage";
        }
    }
};

export default moduleTypeManager;
